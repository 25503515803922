@use '../variables.scss';

.Timeline {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .TimelineVerticalLine {
        border-left: 1vmin solid black;
    }

    .TimelineCardsColumn {
        display: flex;
        flex-direction: column;
        gap: 5vmin;

    }
.TimelineCardContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: .5vw;
    .TimelineDate{
        font-weight: bolder;
        font-size: 2vmin;
        color: black;
        filter: drop-shadow(0 0 5px white);
    }
    .TimelineCard {
        background-color: white;
        height: 25vmin;
        width: 35vmin;
        display: flex;
        flex-direction: column;
        font-size: 2vmin;
        border-radius: 3vmin;
        padding: 1vmin;

        .TimeLineCardFooter {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;

            .TimelineCardCompanyIcon {
                height: 10vmin;
                width: 10vmin;
            }

            .TimelineCardReadMore {
                font-weight: bolder;
                cursor: pointer;
            }

            .TimelineCardReadMore:hover {
                color: purple;
            }
        }


    }
}

    .TimelineCardSpace {
        height: 25vmin;
        width: 35vmin;
    }
}

.TimelineModal {
    display: none;
    /* Hidden by default */
    z-index: 1;
    /* Sit on top */
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */

    /* Modal Content */
    .TimelineModalContent {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 80%;


        .TimelineCardHeader {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .TimelineCardCompanyIcon {
                height: 10vmin;
                width: 10vmin;
            }
        }

        .TimelineCardAccomplishments {
            text-align: justify;
        }

        /* The Close Button */
        .TimelineModalClose {
            color: #aaaaaa;
            float: right;
            font-size: 28px;
            font-weight: bold;
        }

        .TimelineModalClose:hover,
        .TimelineModalClose:focus {
            color: #000;
            text-decoration: none;
            cursor: pointer;
        }



    }



}