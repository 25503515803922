
.Education{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5vmin;
    height: 100vw;


.EducationPicture{
    height: 45vw;
    width: 90vw;
    border-radius: 5vmin;

}

.EducationDegree{
    color: #013579;
    font-size: 3vmin;
    background-color: white;
    border-radius: 5vmin;
    padding: 1vmin;
    font-weight: bold;
}
}