@import '../variables.scss';
@import './Skills.scss';
@import './Timeline.scss';
@import './Education.scss';

.SoftwarePage{
  display: flex;
  flex-direction: column;
  align-content: center;

  .SoftwareContent{
    height: 80vh;
    overflow-y: auto;

  }
}

/* Software Menu */

.SoftwareMenu{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  .SoftwareMenuItem{
    margin: 15px;
    cursor: pointer;
    font-family: "Copperstone";
    font-weight: 700;
    font-size: large;
    text-shadow: stroke(3, white);
  }
  
  
  .SoftwareMenuItem:hover{
    margin: 15px;
    cursor: pointer;
    font-family: "Copperstone";
    font-weight: 700;
    font-size: large;
  
    // only difference between hover and regular
    cursor: pointer;
    color: purple;
  }
  
  .SelectedSoftwareMenuItem{
    margin: 15px;
    cursor: pointer;
    font-family: "Copperstone";
    font-weight: 700;
    font-size: large;
    color: purple;
    text-shadow: stroke(3, white);
  }

}





