@import "./variables.scss";

.Homepage {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  .HomepagePicture {
    filter: drop-shadow(0 0 5px white);
    height: 50vh;
    width: 90vw;
  }

  .HomepageTitle {
    font-family: 'Copperstone';
    color: black;
    text-shadow: stroke(2, white);
    filter: drop-shadow(0 0 5px white);
    text-shadow: stroke(3, white);

  }

  .HomepageIcons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90vw;
    filter: invert(1);
    align-items: center;
    
  }

  .HomepageIconsBottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90vw;
    filter: invert(1);
  }

  .HomepageIconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    .HomepageIconText {
      font-family: 'Copperstone';
      color: black;
      filter: drop-shadow(0 0 5px white);
      text-shadow: stroke(3, white);
      font-size: 3vmin;
    }
    .HomepageIconText:hover {
      color:white;
      text-shadow: stroke(1, black);

    }
    .HomepageIcon {
      height: 10vh;
      filter: invert(1)
    }

    .HomepageIcon:hover {
      cursor: pointer;
      filter: invert(0)
    }

  }
}