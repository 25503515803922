@import './variables.scss';



.MusicHeader{
  color: white;
  text-decoration: none;
  text-shadow: stroke(3, black);
}
.MusicHeader:hover{
  color: purple;
  text-decoration: none;
}
.MusicCell{
}

.SoundCloudSongs{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  flex-flow: wrap;
  gap: 5vmin;
  height: 80vh;
  overflow-y: auto;
}