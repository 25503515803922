// Menu bar
@import './variables.scss';




//Navigation Section In Menu bar
.Navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 1vmin;
  padding: .5vmin;
  gap: 3vmin;

  .NavigationIcon {
    width: 10vw;
    filter: invert(1);
  }
  
  .NavigationIcon:hover {
    width: 10vw;
    color: purple;
    cursor: pointer;
  }

  .NavigationItem {
    cursor: pointer;
    font-family: "Copperstone";
    font-weight: 700;
    font-size: large;
    font-size: 3vmin;
    text-shadow: stroke(3, white);
    color:black
  }

  .NavigationItem:hover {
    cursor: pointer;
    font-family: "Copperstone";
    font-weight: 700;

    // only difference between hover and regular
    cursor: pointer;
    color: purple;
  }

}