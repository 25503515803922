/* Software Skills */

.SoftwareSkillCards {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 1vmin;
  /* row-gap column gap */
  flex-flow: wrap;
  overflow-y: auto;

  .SoftwareSkillCard {
    border-radius: 2vmin;
    border-color: black;
    background-color: white;
    border-style: solid;
    width: calc(45vmin);
    height: calc(42vmin);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .SoftwareSkillCardTitle {
      font-size: calc(2.5vmin);
      font-weight: bolder;
      text-decoration: underline;
    }

    .SoftwareSkillCardSkills {
      display: flex;
      justify-content: space-evenly;
      flex-flow: wrap;
      gap: 1vmin 5vmin;
      height: calc(35vmin);
      overflow-y: auto;
      height: 32vmin;

      .SoftwareSkillCardContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        height: 15vmin;
        width: 10vmin;

        .SoftwareSkillIcon {
          max-height: 7.5vmin;
          max-width: 7.5vmin;
          cursor: pointer;

        }

        .SoftwareSkillCardSkillName {
          font-size: calc(2vmin);
          font-weight: bold
        }
      }

      .SoftwareSkillCardContainer:hover {
        color: purple;
        cursor: pointer;
      }
    }

  }

}