@import './variables.scss';

.App {
  background-image: url("/images/snowpath.jpg");
  background-color: #cccccc;
  background-size: cover;
  flex-direction: column;
  display: flex;
  height: 100vh;
  justify-content: space-between;
}

.Section {
  display: flex;
  flex-direction: column;
  text-align: center;
  overflow-y: auto;
}


.Footer{
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.Footer a{
  font-size: 2vmin;
  color: black;
}

.FooterIconCredit{
  font-size:2vmin
}